import {
  mensagemPadrao,
  SELECT_NACIONALIDADES,
  SELECT_PAISES
} from 'src/dados-estaticos'
import {
  Escolaridade,
  FormacaoPorExtenso,
  FormaPagamentoCobranca,
  TipoUsuario
} from 'src/tipos'
import * as Yup from 'yup'

const caracter = Yup.string().required(mensagemPadrao)
const codigoSegurancaMensagem = 'CVV inválido'

const schemaSenha = Yup.object().shape({
  novaSenha: Yup.string().senha().required(mensagemPadrao),
  repetirSenha: Yup.string()
    .required(mensagemPadrao)
    .oneOf([Yup.ref('novaSenha'), null], 'As senhas não conferem')
})

export const schemaCursoLivreDadosBasicos = Yup.object().shape({
  nacionalidade: caracter,
  cpf: Yup.string()
    .cpf()
    .nullable()
    .when('nacionalidade', {
      is: SELECT_NACIONALIDADES[0].id,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  nome: caracter,
  nomeSocial: Yup.string()
    .nullable()
    .when('possuiNomeSocial', {
      is: true,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required(mensagemPadrao),
  celular: Yup.string().telefone().required(mensagemPadrao),
  profissaoOutros: Yup.string()
    .nullable()
    .when('profissao', {
      is: FormacaoPorExtenso.Outra,
      then: field => field.required('Informe qual profissão')
    })
})

export const schemaCursoLivreDadosBasicosComSenha = schemaCursoLivreDadosBasicos.concat(
  schemaSenha
)

export const schemaPagamentoPlanoIsento = Yup.object().shape({})

export const schemaPagamentoCartaoRecorrencia = Yup.object().shape({
  numeroCartao: Yup.string().cartaoCredito().required(mensagemPadrao),
  nomeCartao: caracter,
  validadeCartao: Yup.string().validadeCartaoCredito().required(mensagemPadrao),
  codigoSeguranca: Yup.string()
    .test(
      'tamanho',
      codigoSegurancaMensagem,
      value =>
        !!value &&
        !Number.isNaN(Number(value)) &&
        value.length >= 3 &&
        value.length <= 4
    )
    .required(mensagemPadrao)
})

export const schemaPagamentoCartaoCredito = Yup.object().shape({
  metodoPagamento: caracter,
  numeroCartao: Yup.string().cartaoCredito().required(mensagemPadrao),
  nomeCartao: caracter,
  validadeCartao: Yup.string().validadeCartaoCredito().required(mensagemPadrao),
  codigoSeguranca: Yup.string()
    .test(
      'tamanho',
      codigoSegurancaMensagem,
      value =>
        !!value &&
        !Number.isNaN(Number(value)) &&
        value.length >= 3 &&
        value.length <= 4
    )
    .required(mensagemPadrao),
  parcelas: caracter
})

export const schemaPagamentoBoleto = Yup.object().shape({
  metodoPagamento: caracter,
  diaVencimento: caracter
})

export const schemaPagamentoPix = Yup.object().shape({
  metodoPagamento: caracter
})

export const schemaPosGraduacaoDadosBasicos = schemaCursoLivreDadosBasicos.concat(
  Yup.object().shape({
    documentoIdentidade: caracter,
    dataNascimento: Yup.string().dataBrasileira().required(mensagemPadrao),
    corRaca: caracter,
    escolaridade: caracter,
    mesAnoColacaoGrau: Yup.string()
      .dataBrasileiraNullable()
      .when('escolaridade', {
        is:
          Escolaridade[Escolaridade.EnsinoSuperiorEmAndamento].toString() ||
          Escolaridade[Escolaridade.EnsinoSuperiorCompleto].toString(),
        then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
      }),
    profissao: caracter,
    endereco: Yup.object().shape({
      pais: caracter,
      cep: Yup.string()
        .cep()
        .nullable()
        .when('pais', {
          is: SELECT_PAISES[0].id,
          then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
        }),
      logradouro: caracter,
      numero: caracter,
      complemento: Yup.string(),
      bairro: caracter,
      cidade: caracter,
      uf: caracter
    }),
    celular: Yup.string().telefone().required(mensagemPadrao),
    profissaoOutros: Yup.string()
      .nullable()
      .when('profissao', {
        is: FormacaoPorExtenso.Outra,
        then: field => field.required('Informe qual profissão')
      })
  })
)

export const schemaPosGraduacaoDadosBasicosComSenha = schemaPosGraduacaoDadosBasicos.concat(
  schemaSenha
)

export const schemaPosGraduacaoPlanoPagamento = Yup.object().shape({
  planoPagamentoId: caracter,
  diaVencimento: Yup.string()
    .nullable()
    .when(['chkPagamentoRecorrente', 'formPagamentoParcela'], {
      is: (pagamentoRecorrente, formPagamentoParcela) => {
        return (
          formPagamentoParcela === null ||
          formPagamentoParcela === FormaPagamentoCobranca.Boleto ||
          pagamentoRecorrente === false ||
          pagamentoRecorrente === null
        )
      },
      then: field => field.required(mensagemPadrao)
    })
})

export const schemaResponsavelFinanceiroEndereco = Yup.object().shape({
  pais: caracter,
  cep: Yup.string()
    .cep()
    .nullable()
    .when('pais', {
      is: SELECT_PAISES[0].id,
      then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
    }),
  logradouro: caracter,
  numero: caracter,
  complemento: Yup.string(),
  bairro: caracter,
  cidade: caracter,
  uf: caracter
})

export const schemaResponsavelFinanceiroPF = Yup.object().shape({
  responsavelFinanceiro: Yup.object().shape({
    tipo: caracter,
    nacionalidade: Yup.string()
      .nullable()
      .when('tipo', {
        is: TipoUsuario.PF,
        then: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
      }),
    cpfCnpj: Yup.string()
      .nullable()
      .cpfCnpj('Cpf/Cnpj inválido')
      .when(['tipo', 'nacionalidade'], {
        is: (tipo, nacionalidade) =>
          tipo === TipoUsuario.PF &&
          nacionalidade === SELECT_NACIONALIDADES[1].id,
        then: (fieldSchema: any) => fieldSchema.nullable(),
        otherwise: (fieldSchema: any) => fieldSchema.required(mensagemPadrao)
      }),
    email: Yup.string()
      .email('Informe um e-mail válido')
      .required(mensagemPadrao),
    documentoIdentidade: Yup.string().when('tipo', {
      is: TipoUsuario.PF,
      then: fieldSchema => fieldSchema.required()
    }),
    nome: caracter,
    celular: Yup.string().telefone().required(mensagemPadrao),
    endereco: Yup.object()
      .nullable()
      .when('enderecoDiferente', {
        is: true,
        then: (fieldSchema: any) =>
          fieldSchema.concat(schemaResponsavelFinanceiroEndereco)
      }),
    inscricaoEstadual: Yup.string()
      .nullable()
      .when('tipo', {
        is: TipoUsuario.PJ,
        then: fieldSchema => fieldSchema.required(mensagemPadrao)
      })
  })
})

export const schemaPosGraduacaoPlanoPagamentoNovoResponsavelFinanceiro = schemaPosGraduacaoPlanoPagamento.concat(
  schemaResponsavelFinanceiroPF
)
